import React from "react";
import { usePagination, DOTS } from "../../hooks/usePagination.jsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import "./pagination.scss";

const Pagination = ({ location, numPage, siblingCount = 1, currentPage }) => {
  const paginationRange = usePagination({
    currentPage,
    siblingCount,
    numPage,
  });

  if (location === undefined) return null;

  const isLastUrlPartNumber = (url) => {
    let lastPart = url.substr(url.lastIndexOf("/") + 1);

    if (lastPart === "")
      lastPart = url.slice(0, -1).substr(url.slice(0, -1).lastIndexOf("/") + 1);

    if (isNaN(parseInt(lastPart, 10))) return false;

    return true;
  };

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  let baseLocation;
  if (isLastUrlPartNumber(location)) {
    // remove last part
    let locationPath = location;
    if (location.slice(-1) === "/") {
      locationPath = location.slice(0, -1);
    }

    baseLocation = locationPath.substring(0, locationPath.lastIndexOf("/"));
  } else {
    if (location.slice(-1) === "/") {
      baseLocation = location.slice(0, -1);
    } else {
      baseLocation = location;
    }
  }

  const isFirst = currentPage === 1;
  const isLast = currentPage === paginationRange[paginationRange.length - 1];
  const prevPage =
    currentPage - 1 === 1
      ? `${baseLocation}/`
      : `${baseLocation}/${(currentPage - 1).toString()}/`;
  const nextPage = `${baseLocation}/${(currentPage + 1).toString()}/`;
  return (
    <ul className="pagination-container">
      {!isFirst && (
        <li>
          <a href={prevPage} rel="prev">
            <FontAwesomeIcon icon={faChevronLeft} />
          </a>
        </li>
      )}
      {paginationRange.map((pageNumber, i) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item dots" key={i}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={i}
            className={`pagination-item ${
              pageNumber === currentPage ? "current-number" : ""
            }`}
          >
            <a
              href={
                pageNumber === 1
                  ? `${baseLocation}/`
                  : `${baseLocation}/${pageNumber}/`
              }
            >
              {pageNumber}
            </a>
          </li>
        );
      })}

      {!isLast && (
        <li>
          <a href={nextPage} rel="next">
            <FontAwesomeIcon icon={faChevronRight} />
          </a>
        </li>
      )}
    </ul>
  );
};

export default Pagination;
