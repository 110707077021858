import React, {useEffect} from "react";
import Button from "../../button/button";
import ReactMarkdown from "react-markdown";
import "./blocImage.scss";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import CustomBreadcrumb from "../../customBreadcrumb/customBreadcrumb";
import {useBreadcrumb} from "gatsby-plugin-breadcrumb";

export default function BlocImage({
                                      imagePath,
                                      title,
                                      text,
                                      btnText,
                                      btnUrl,
                                      className,
                                      children,
                                      file,
                                      Fil_ariane,
                                      location
                                  }) {


    const crumbs = [{pathname: '/'}];
    crumbs.push({
        pathname: location?.pathname,
        crumbLabel: Fil_ariane
    });

    function displayButtons() {
        if (btnUrl && btnText) {
            return (
                <>
                    {" "}
                    <a
                        href={btnUrl}
                        target="_self"
                    >
                        <Button
                            className="bloc-image__btn"
                            text={btnText}
                        />
                    </a>
                </>
            );
        }
        return null;
    }

    return (
        <section className={`section-container-block bloc-image ${className || ""}`}>
            <div className="bloc-image__content-container">
                <div className={'content-breadcrumb'}>
                    {Fil_ariane && (
                        <CustomBreadcrumb crumbs={crumbs}/>
                    )}
                </div>
                <div className="bloc-image__content">
                    <h2>
                        <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            remarkPlugins={[remarkGfm]}
                            children={title ?? ""}
                        />
                    </h2>
                    {children ||
                        <>
                            {" "}
                            {text && <p>{text}</p>}
                            {
                                file ? (
                                    <>
                                        {" "}
                                        <a
                                            download={file.localFile.originalName}
                                            href={file.localFile.publicURL}
                                            target="_blank"
                                        >
                                            <Button
                                                className="bloc-image__btn"
                                                text={btnText}
                                            />
                                        </a>
                                    </>
                                ) : displayButtons()
                            }
                        </>
                    }
                </div>
            </div>
            <div
                className="bloc-image__image"
                style={{backgroundImage: `url(${imagePath})`}}
            ></div>
        </section>
    );
}
