import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Button from "../../components/button/button";

import "./searchBar.scss";

export default function SearchBar({onChange}) {
  return (
    <div className="searchBar">
      <div className="searchBar__container">
        <FontAwesomeIcon className="searchBar__icon" icon={faSearch} />
        <input
          type="text"
          name="search"
          placeholder="Entrer un mot clé"
          className="searchBar__search"
          onChange={onChange}
        />
        <Button className="searchBar__btn" text="Rechercher" />
      </div>
    </div>
  );
}
