import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import React from "react";
import CustomBreadcrumb from "../../customBreadcrumb/customBreadcrumb";

import "./headerLight.scss";

export default function HeaderLight({ title, location, crumbLabel, center = false }) {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: crumbLabel,
  });

  return (
    <header className={`header-light ${center ? "center" : ""}`}>
      <h1 className="header-light__heading">{title}</h1>
      <CustomBreadcrumb crumbs={crumbs} theme="light" />
    </header>
  );
}
