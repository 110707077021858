import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import BlocImage from "../../components/dynamicZone/blocImage/blocImage";
import HeaderLight from "../../components/header/headerLight/headerLight";
import ArticleCard from "../../components/card/articleCard/articleCard";
import Pagination from "../../components/pagination/pagination";

import articleImg from "../../images/articles/photo-article-default.webp";
import imgBlocImage from "../../images/articles/photo-bloc-image-articles.webp";

import SearchBar from "../../components/searchBar/searchBar";

import "./style.scss";

const ArticlesPage = ({ location, data, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const strapiArticles = data.articles.nodes;
  const [articles, setArticles] = useState(strapiArticles);

  const stateArticles = data.articles.nodes;

  const searchArticles = (input) => {
    if (input.target.value === "") {
      setArticles(stateArticles);
      return;
    }
    const tempArticles = pageContext.articles.filter((article) => {
      return article.Titre.toLowerCase().includes(
        input.target.value.toLowerCase()
      );
    });
    setArticles(tempArticles);
  };

  return (
    <>
      <Helmet>
        <body className="articles" />
      </Helmet>
      <Layout>
        <SEO title="Articles" description="Définitions, cas sinistre, expertise et assurance, découvrez tous nos articles à destination des professionnels du bâtiment et des travaux publics en recherche de réponses."/>
        <HeaderLight
          title={
            <>
              <strong>Lire</strong> nos <br /> articles
            </>
          }
          location={location}
          crumbLabel="Articles"
        />

        <SearchBar onChange={searchArticles} />

        <section className="articles__background">
          <div className ="articles__list">
            {articles.slice(0, 6).map((article, i) => {
              return (
                  <div>
                    <ArticleCard
                        key={i}
                        size="small"
                        title={article.Titre}
                        date={article.Date_De_Publication}
                        slug={article.Slug}
                        type="article"
                        img={
                          article.Image_Vignette
                              ? article.Image_Vignette.localFile
                              : articleImg
                        }
                    />
                  </div>
              );
            })}
          </div>
        </section>

        <BlocImage
          imagePath={imgBlocImage}
          title="<strong>À nous la complexité, <br>à vous la tranquillité.</strong>"
          text="Parce qu’une équipe à votre service, c’est l’assurance d’être performants ensemble."
          btnText="Notre vision"
          btnUrl="/nos-valeurs"
        />

        {articles.length > 6 && (
          <section className="articles__card">
            <div className="articles__list">
              {articles.slice(6).map((article, i) => {
                return (
                    <div>
                      <ArticleCard
                          key={i}
                          size="small"
                          slug={article.Slug}
                          type="article"
                          title={article.Titre}
                          date={article.Date_De_Publication}
                          img={
                            article.Image_Vignette
                                ? article.Image_Vignette.localFile
                                : articleImg
                          }
                      />
                    </div>
                );
              })}
            </div>
          </section>
        )}

        <div className="articles__pagination">
          <Pagination
            location={location.href}
            numPage={numPages}
            currentPage={currentPage}
          />
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ArticlesPageQuery($skip: Int!, $limit: Int!) {
    articles: allStrapiArticle(
      sort: { 
        fields: [Important, Date_De_Publication], order: [DESC, DESC] 
      }
      limit: $limit
      skip: $skip
      filter: { Indexer: { eq: true } }
    ) {
      nodes {
        Important
        Titre
        Slug
        Date_De_Publication(formatString: "DD/MM/YYYY")
        Image_Vignette {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
  }
`;

export default ArticlesPage;
